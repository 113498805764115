/* eslint-disable sort-imports, import/order */
// Dont change the order of the imports because of circular dependencies
import { createRoot } from "react-dom/client";
import App from "./main/common/components/App";
import Firebase from "./main/common/firebase/firebase";
import countries from "i18n-iso-countries";
import germanCountryNames from "i18n-iso-countries/langs/de.json";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { setupIonicReact } from "@ionic/react";
import { ErrorBoundary } from "./ErrorBoundary";
import { setupInvoiceApi } from "./configuration/setupInvoiceApi";
import { setupMailApi } from "./configuration/setupMailApi";
import { setupTrackerApi } from "./configuration/setupTrackerApi";
import { setupNotificationApi } from "./configuration/setupNotificationApi";
import { setupFinApi } from "./configuration/setupFinApi";
import { environment } from "./main/config/config";
import "./polyfills";
import { setupDatevApi } from "./configuration/setupDatevApi";
import { isPlatform } from "@ionic/core";
import { setupOcrApi } from "./configuration/setupOcrApi";
import { setupCustomerPortalApi } from "./configuration/setupCustomerPortalApi";
import { setupEInvoiceApi } from "@/configuration/setupEInvoiceApi";
import { setupGeoServiceApi } from "@/configuration/setupGeoServiceApi";

Firebase.initialize({ runningInEmulator: environment === "local", runningInTests: false });
setupIonicReact({
    swipeBackEnabled: false,
    innerHTMLTemplatesEnabled: true,
    platform: {
        /** The default `mobile` function returns true for everything with a touchscreen.
         * We do not want that because then monitors or notebooks with touchscreens are classified as mobile.
         **/
        mobile: () => {
            return isPlatform("ios") || isPlatform("android");
        },
    },
});
setupInvoiceApi();
setupMailApi();
setupFinApi();
setupTrackerApi();
setupDatevApi();
setupNotificationApi();
setupOcrApi();
setupEInvoiceApi();
setupGeoServiceApi();
setupCustomerPortalApi();
countries.registerLocale(germanCountryNames);

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
);

defineCustomElements(window);

declare global {
    interface Window {
        hsConversationsSettings: Record<string, any>;
        hsConversationsOnReady: Array<() => void>;
        HubSpotConversations: {
            on: any;
            off: any;
            widget: {
                status: () => { loaded: boolean; pending: boolean };
                load: (params?: { widgetOpen: boolean }) => void;
                remove: () => void;
                open: () => void;
                close: () => void;
                refresh: (parameters?: { openToNewThread: boolean }) => void;
            };
        };
        disableBugsnag?: boolean;
    }
}
