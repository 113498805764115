import { CompanyMeta } from "@farmact/model/src/model/CompanyMeta";
import { User } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { Firebase } from "@/firebase";
import { useCompanyId } from "./useCompanyId";

type UseFetchOrganizationContextDataParams = {
    authUser: User | undefined;
    companyMeta: CompanyMeta | undefined;
};

export function useFetchOrganizationContextData(params: UseFetchOrganizationContextDataParams) {
    const companyId = useCompanyId();

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                resourcesLoading: false,
                resources: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllResources(), snapshot => {
            const resources = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                resourcesLoading: false,
                resources,
            });
        });
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                servicesLoading: false,
                services: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllServices(), snapshot => {
            const services = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                servicesLoading: false,
                services,
            });
        });
    }, [companyId]);

    const loggedInAppUserId = params.authUser?.uid;
    const supportEmployeeId = params.companyMeta?.supportEmployeeId;

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                employeesLoading: false,
                employees: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllEmployees(), snapshot => {
            const allEmployees = snapshot.docs.map(doc => doc.data());

            const loggedInEmployee = allEmployees.find(employee => {
                return employee.appUserId === loggedInAppUserId;
            });
            const loggedInEmployeeIsSupport = loggedInEmployee?.id === supportEmployeeId;

            if (loggedInEmployeeIsSupport) {
                useOrganizationStore.setState({
                    employeesLoading: false,
                    employees: allEmployees,
                });
            } else {
                useOrganizationStore.setState({
                    employeesLoading: false,
                    employees: allEmployees.filter(employee => {
                        return employee.id !== supportEmployeeId;
                    }),
                });
            }
        });
    }, [companyId, loggedInAppUserId, supportEmployeeId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                machinesLoading: false,
                machines: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllMachines(), snapshot => {
            const machines = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                machinesLoading: false,
                machines,
            });
        });
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                loadingOrUnloadingPointsLoading: false,
                loadingOrUnloadingPoints: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllCommonLoadingOrUnloadingPoints(), snapshot => {
            const loadingOrUnloadingPoints = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                loadingOrUnloadingPointsLoading: false,
                loadingOrUnloadingPoints,
            });
        });
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                resourceTagsLoading: false,
                resourceTags: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllResourceTags(), snapshot => {
            const resourceTags = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                resourceTagsLoading: false,
                resourceTags,
            });
        });
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                suplliersLoading: false,
                suppliers: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllSuppliers(), snapshot => {
            const suppliers = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                suplliersLoading: false,
                suppliers,
            });
        });
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                customerTagsLoading: false,
                customerTags: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllCustomerTags(), snapshot => {
            const customerTags = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                customerTagsLoading: false,
                customerTags,
            });
        });
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                maschinenringsLoading: false,
                maschinenrings: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllMaschinenrings({ include: "non-archived" }), snapshot => {
            const maschinenrings = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                maschinenringsLoading: false,
                maschinenrings,
            });
        });
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            useOrganizationStore.setState({
                gasStationsLoading: false,
                gasStations: [],
            });
            return;
        }

        return onSnapshot(Firebase.instance().getAllGasStations(), snapshot => {
            const gasStations = snapshot.docs.map(doc => doc.data());
            useOrganizationStore.setState({
                gasStationsLoading: false,
                gasStations,
            });
        });
    }, [companyId]);
}
