"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.F_CODE = exports.F_ERROR_PREFIX = void 0;
exports.F_ERROR_PREFIX = `FEIS`;
/* forward stack trace */
var F_CODE;
(function (F_CODE) {
    // ==============
    // GENERAL ERRORS
    // ==============
    // 4xx
    F_CODE["FEIS_400"] = "Bad Request";
    F_CODE["FEIS_401"] = "Unauthorized";
    F_CODE["FEIS_402"] = "Payment Required";
    F_CODE["FEIS_403"] = "Forbidden";
    F_CODE["FEIS_404"] = "Not Found";
    F_CODE["FEIS_405"] = "Method Not Allowed";
    F_CODE["FEIS_406"] = "Not Acceptable";
    F_CODE["FEIS_407"] = "Proxy Authentication Required";
    F_CODE["FEIS_408"] = "Request Timeout";
    F_CODE["FEIS_409"] = "Conflict";
    F_CODE["FEIS_410"] = "Gone";
    F_CODE["FEIS_411"] = "Length Required";
    F_CODE["FEIS_412"] = "Precondition Failed";
    F_CODE["FEIS_413"] = "Payload Too Large";
    F_CODE["FEIS_414"] = "URI Too Long";
    F_CODE["FEIS_415"] = "Unsupported Media Type";
    F_CODE["FEIS_416"] = "Range Not Satisfiable";
    F_CODE["FEIS_417"] = "Expectation Failed";
    F_CODE["FEIS_418"] = "I`m a teapot (RFC 2324, RFC 7168)";
    F_CODE["FEIS_421"] = "Misdirected Request";
    F_CODE["FEIS_422"] = "Unprocessable Entity";
    F_CODE["FEIS_423"] = "Locked (WebDAV; RFC 4918)";
    F_CODE["FEIS_424"] = "Failed Dependency (WebDAV; RFC 4918)";
    F_CODE["FEIS_425"] = "Too Early (RFC 8470)";
    F_CODE["FEIS_426"] = "Upgrade Required";
    F_CODE["FEIS_428"] = "Precondition Required (RFC 6585)";
    F_CODE["FEIS_429"] = "Too Many Requests (RFC 6585)";
    F_CODE["FEIS_431"] = "Request Header Fields Too Large (RFC 6585)";
    F_CODE["FEIS_451"] = "Unavailable For Legal Reasons (RFC 7725)";
    // 5xx
    F_CODE["FEIS_500"] = "Internal Server Error";
    F_CODE["FEIS_501"] = "Not Implemented";
    F_CODE["FEIS_502"] = "Bad Gateway";
    F_CODE["FEIS_503"] = "Service Unavailable";
    F_CODE["FEIS_504"] = "Gateway Timeout";
    F_CODE["FEIS_505"] = "HTTP Version not supported";
    F_CODE["FEIS_506"] = "Variant Also Negotiates";
    F_CODE["FEIS_507"] = "Insufficient Storage";
    F_CODE["FEIS_508"] = "Loop Detected";
    F_CODE["FEIS_509"] = "Bandwidth Limit Exceeded";
    F_CODE["FEIS_510"] = "Not Extended";
    F_CODE["FEIS_511"] = "Network Authentication Required";
    /*
     * 10: e-invoice-service
     * 15: pdf-service
     * 20: firebase
     */
    /* 10: e-invoice-service */
    F_CODE["FEIS_400_10"] = "Bad Request (e-invoice-service)";
    F_CODE["FEIS_400_10_0100"] = "receipt.receiptNumber missing";
    F_CODE["FEIS_400_10_0101"] = "receipt.date missing";
    F_CODE["FEIS_400_10_0102"] = "receipt.date has corrupt format";
    F_CODE["FEIS_400_10_0103"] = "receipt.date or receipt.cancellation.date missing";
    F_CODE["FEIS_400_10_0104"] = "receipt.date or receipt.cancellation.date has corrupt format";
    F_CODE["FEIS_400_10_0105"] = "receipt.buyerReference missing";
    F_CODE["FEIS_400_10_0106"] = "receipt.contactPerson missing";
    F_CODE["FEIS_400_10_0107"] = "receipt.contactPhone missing";
    F_CODE["FEIS_400_10_0108"] = "receipt.contactEmail missing";
    F_CODE["FEIS_400_10_0109"] = "receipt.customerSalesTaxId missing";
    F_CODE["FEIS_400_10_0110"] = "receipt.billingAddress.countryCode not supported";
    F_CODE["FEIS_400_10_0111"] = "receipt.billingAddress.city missing";
    F_CODE["FEIS_400_10_0112"] = "receipt.billingAddress.zip missing";
    F_CODE["FEIS_400_10_0113"] = "receipt.billingAddress.countryCode missing";
    F_CODE["FEIS_400_10_0114"] = "receipt.billingAddress.street missing";
    F_CODE["FEIS_400_10_0115"] = "receipt.paymentInfos.paymentType not supported";
    F_CODE["FEIS_400_10_0116"] = "operatingUnit.name missing";
    F_CODE["FEIS_400_10_0117"] = "operatingUnit.salesTaxId has to start with \"DE\"";
    F_CODE["FEIS_400_10_0118"] = "operatingUnit.salesTaxId has to start with \"CHE\"";
    F_CODE["FEIS_400_10_0119"] = "operatingUnit.salesTaxId has to start with \"AT\"";
    F_CODE["FEIS_400_10_0120"] = "operatingUnit.countryCode not supported";
    F_CODE["FEIS_400_10_0121"] = "operatingUnit.city missing";
    F_CODE["FEIS_400_10_0122"] = "operatingUnit.zip missing";
    F_CODE["FEIS_400_10_0123"] = "operatingUnit.countryCode missing";
    F_CODE["FEIS_400_10_0124"] = "operatingUnit.street missing";
    F_CODE["FEIS_400_10_0125"] = "appCompany.settings.currency not supported";
    F_CODE["FEIS_400_10_0126"] = "receipt.billingAddress.name missing";
    F_CODE["FEIS_400_10_0127"] = "position.name or position.resourceName missing";
    F_CODE["FEIS_400_10_0128"] = "customer.mail missing and no fallback mail could be found (customer.contactPersons)";
    F_CODE["FEIS_400_10_0129"] = "receipt.customerSalesTaxId has to start with \"DE\"";
    F_CODE["FEIS_400_10_0130"] = "receipt.customerSalesTaxId has to start with \"CHE\"";
    F_CODE["FEIS_400_10_0131"] = "receipt.customerSalesTaxId has to start with \"AT\"";
    F_CODE["FEIS_400_10_0132"] = "operatingUnit.salesTaxId missing";
    F_CODE["FEIS_500_10"] = "Internal Server Error (e-invoice-service)";
    F_CODE["FEIS_500_10_0001"] = "Unsupported invoiceTypeCode";
    F_CODE["FEIS_500_10_0002"] = "Unsupported invoiceCurrencyCode";
    F_CODE["FEIS_500_10_0003"] = "Cannot detect format of e-invoice";
    /* 15: service (pdf) */
    F_CODE["FEIS_500_15"] = "Internal Server Error (pdf-service)";
    F_CODE["FEIS_500_15_0010"] = "Cannot setup tmp path";
    F_CODE["FEIS_500_15_0011"] = "Cannot read tmp file";
    F_CODE["FEIS_500_15_0020"] = "Cannot find ghostscript";
    F_CODE["FEIS_500_15_0021"] = "Cannot get ghostscript version";
    F_CODE["FEIS_500_15_0022"] = "Ghostscript process exited with non zero exit code";
    F_CODE["FEIS_500_15_0030"] = "Cannot find verapdf";
    F_CODE["FEIS_500_15_0031"] = "Cannot get verapdf version";
    F_CODE["FEIS_500_15_0032"] = "Verapdf process exited with non zero exit code";
    /* 20: firebase */
    F_CODE["FEIS_500_20"] = "Internal Server Error (firebase)";
    F_CODE["FEIS_500_20_0010"] = "Cannot get receipt from firebase";
    F_CODE["FEIS_500_20_0011"] = "Cannot get receipt from firebase - not found";
    F_CODE["FEIS_500_20_0012"] = "Cannot get receipt from firebase - unknown receipt type";
    F_CODE["FEIS_500_20_0020"] = "Cannot get operatingUnit from firebase";
    F_CODE["FEIS_500_20_0021"] = "Cannot get operatingUnit from firebase - not found";
    F_CODE["FEIS_500_20_0030"] = "Cannot get appCompany from firebase";
    F_CODE["FEIS_500_20_0031"] = "Cannot get appCompany from firebase - not found";
    F_CODE["FEIS_500_20_0040"] = "Cannot get customer from firebase";
    F_CODE["FEIS_500_20_0041"] = "Cannot get customer from firebase - not found";
    F_CODE["FEIS_500_20_0050"] = "Cannot get file from storage";
    /* 30: finax */
})(F_CODE || (exports.F_CODE = F_CODE = {}));
