// rewrite OpenAPI BASE URL during development
import { OpenAPI as GeoServiceApi } from "@farmact/geo-service-client";
import { environment } from "@/config/config";

// must be equal to the value in vite.config.ts
const localGeoServiceApiPath = "/localApi/geo";

export function setupGeoServiceApi() {
    if (environment == "local") {
        GeoServiceApi.BASE = localGeoServiceApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        GeoServiceApi.BASE = "https://dev.api.geo.farmact.de";
    }

    if (environment === "beta") {
        GeoServiceApi.BASE = "https://beta.api.geo.farmact.de";
    }

    if (environment === "prod") {
        GeoServiceApi.BASE = "https://api.geo.farmact.de";
    }
}
