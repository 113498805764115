import { INCOMING_BILLS, INCOMING_CREDIT_NOTES } from "@farmact/model/src/constants/farmActAppRoutes";
import { SubMenu } from "@/components/structure/Page/SideNav/SideMenuTile/subMenus/SubMenu/SubMenu";
import { SubMenuItem } from "@/components/structure/Page/SideNav/SideMenuTile/subMenus/SubMenuItem/SubMenuItem";
import { useHasFeatureFlag } from "@/util/customHooks/useHasFeatureFlag";

type IncomingReceiptsMenuProps = {
    isOpen?: boolean;
    isInSplitPane: boolean;
};

export const IncomingReceiptsMenu = (props: IncomingReceiptsMenuProps) => {
    const incomingCreditNotesIsEnabled = useHasFeatureFlag("incomingCreditNotes");

    return (
        <SubMenu isOpen={props.isOpen} cssClass="receipts-menu">
            <SubMenuItem isInSplitPane={!!props.isInSplitPane} name={"Rechnungen"} route={INCOMING_BILLS} />
            {incomingCreditNotesIsEnabled && (
                <SubMenuItem
                    isInSplitPane={!!props.isInSplitPane}
                    name={"Gutschriften"}
                    route={INCOMING_CREDIT_NOTES}
                />
            )}
        </SubMenu>
    );
};
