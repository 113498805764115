import { ServicePriceUnit } from "@farmact/model/src/model/services/ServicePriceUnit";
import { getSingularPluralTextFormatter } from "@farmact/utils/src/formatters";

export function getServicePriceUnitName(unit: ServicePriceUnit, count = 0): string {
    switch (unit) {
        case ServicePriceUnit.FLAT:
            return "Pauschale";
        case ServicePriceUnit.HECTARE:
            return "Hektar";
        case ServicePriceUnit.HOUR:
            return getSingularPluralTextFormatter("Stunde", "Stunden")(count);
        case ServicePriceUnit.SQUAREMETER:
            return "Quadratmeter";
        case ServicePriceUnit.METER:
            return "Meter";
        case ServicePriceUnit.KILOMETER:
            return "Kilometer";
        case ServicePriceUnit.TONS:
            return "Tonnen";
        case ServicePriceUnit.CUBIC_METERS:
            return "Kubikmeter";
        case ServicePriceUnit.SOLID_CUBIC_METERS:
            return "Festmeter";
        case ServicePriceUnit.LITER:
            return "Liter";
        case ServicePriceUnit.LOADS:
            return "Fuhren";
        default:
            unit satisfies never;
            return "";
    }
}

export function getServicePriceShortUnitName(unit: ServicePriceUnit | string): string {
    switch (unit) {
        case ServicePriceUnit.FLAT:
            return "Pauschale";
        case ServicePriceUnit.HECTARE:
            return "ha";
        case ServicePriceUnit.HOUR:
            return "h";
        case ServicePriceUnit.SQUAREMETER:
            return "qm";
        case ServicePriceUnit.METER:
            return "m";
        case ServicePriceUnit.KILOMETER:
            return "km";
        case ServicePriceUnit.TONS:
            return "t";
        case ServicePriceUnit.CUBIC_METERS:
            return "m³";
        case ServicePriceUnit.SOLID_CUBIC_METERS:
            return "Fm";
        case ServicePriceUnit.LITER:
            return "l";
        case ServicePriceUnit.LOADS:
            return "F.";
        default:
            return unit;
    }
}
