import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const IncomingReceiptsMenuPageContent = lazy(() => import("./IncomingReceiptsMenuPageContent"));

function IncomingReceiptsMenuPageInternal() {
    return (
        <DefaultPage>
            <IncomingReceiptsMenuPageContent />
        </DefaultPage>
    );
}

export const IncomingReceiptsMenuPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT)
)(IncomingReceiptsMenuPageInternal);
