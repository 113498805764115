import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CampaignDetailsPageContent = lazy(() => import("./CampaignDetailsPageContent"));

function CampaignDetailsPageInternal() {
    return (
        <DefaultPage>
            <CampaignDetailsPageContent />
        </DefaultPage>
    );
}

export const CampaignDetailsPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER)
)(CampaignDetailsPageInternal);
