"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class TransactionService {
    /**
     * 💰 Get transactions
     * @returns GetTransactionsResponseDto
     * @throws ApiError
     */
    static getTransactions({ companyId, operatingUnitId, dateFrom = '2025-04-03', dateTo = '2025-04-03', onlyIncomingTransactions, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/transactions',
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'onlyIncomingTransactions': onlyIncomingTransactions,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * 💰 Get transaction
     * @returns any
     * @throws ApiError
     */
    static getTransaction({ id, companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/transactions/{id}',
            path: {
                'id': id,
            },
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
}
exports.TransactionService = TransactionService;
