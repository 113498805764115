import { DriverQueryResourceWithAmount } from "@farmact/model/src/model/Order";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceRestrictionType } from "@farmact/model/src/model/services/DriverQuery";
import { useMemo } from "react";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";

export function useDriverQueryResourceWithAmountResources(driverQuery: DriverQueryResourceWithAmount): Resource[] {
    const resources = useOrganizationStore(s => s.resources);

    const restriction = driverQuery.restriction;

    return useMemo(() => {
        if (!restriction) {
            return resources;
        }

        switch (restriction.type) {
            case ResourceRestrictionType.CATEGORY:
                return resources.filter(resource => resource.tags.some(tag => restriction.tags.includes(tag)));
            case ResourceRestrictionType.RESOURCE:
                return resources.filter(resource => resource.id === restriction.resourceId);
        }
    }, [resources, restriction]);
}
