import { TaskRecord } from "@farmact/model/src/model/Order";
import { TaskRecordType, getTaskRecordTypeName } from "@farmact/model/src/model/services/TaskRecords";
import * as Item from "@/components/FaItem";
import { NumberInput } from "@/components/inputs/NumberInput/NumberInput";
import { getServicePriceUnitName } from "@/util/nameUtils/serviceNameUtils";

type OrderTaskRecordsModalContentProps = {
    taskRecords: TaskRecord[];
    onTaskRecordsChange: (taskRecords: TaskRecord[]) => void;
};

export const OrderTaskRecordsModalContent = (props: OrderTaskRecordsModalContentProps) => {
    const getTaskRecord = (taskRecordType: TaskRecordType): TaskRecord | null => {
        return (
            props.taskRecords.find(taskRecord => {
                return taskRecord.type === taskRecordType;
            }) ?? null
        );
    };

    const handleTaskRecordChange = (taskRecordId: TaskRecord["id"], value: number | null) => {
        props.onTaskRecordsChange(
            props.taskRecords.map(taskRecord => {
                if (taskRecord.id === taskRecordId) {
                    return {
                        ...taskRecord,
                        record: value,
                        timeStamp: new Date().toISOString(),
                    };
                }

                return taskRecord;
            })
        );
    };

    return (
        <div className="order-task-records-modal-content">
            {TASK_RECORDS.map(taskRecordType => {
                const taskRecord = getTaskRecord(taskRecordType);
                if (!taskRecord) {
                    return null;
                }

                return (
                    <Item.Root key={taskRecord.id}>
                        <Item.Label>{getTaskRecordTypeName(taskRecordType)}</Item.Label>
                        <Item.Content>
                            <NumberInput
                                placeholder={getTaskRecordTypeName(taskRecordType)}
                                step={0.1}
                                currentValue={taskRecord.record}
                                unit={getServicePriceUnitName(taskRecord.unit)}
                                onChange={value => {
                                    handleTaskRecordChange(taskRecord.id, value);
                                }}
                            />
                        </Item.Content>
                    </Item.Root>
                );
            })}
        </div>
    );
};

const TASK_RECORDS: TaskRecordType[] = [
    TaskRecordType.AREA,
    TaskRecordType.TRACKS,
    TaskRecordType.TONS,
    TaskRecordType.CUBIC_METERS,
    TaskRecordType.SOLID_CUBIC_METERS,
    TaskRecordType.LITER,
    TaskRecordType.LOADS,
];
