import { Service } from "@farmact/model/src/model/services/Service";
import { ServicePriceUnit } from "@farmact/model/src/model/services/ServicePriceUnit";

export function getAreaUnit(service: Service) {
    let value: ServicePriceUnit | undefined = undefined;
    for (const priceBlock of service.calculatedServicePriceBlocks) {
        if (priceBlock.basis === ServicePriceUnit.HECTARE) {
            return ServicePriceUnit.HECTARE;
        } else if (priceBlock.basis === ServicePriceUnit.SQUAREMETER) {
            value = ServicePriceUnit.SQUAREMETER;
        }
    }
    for (const priceBlock of service.calculatedResourcePriceBlocks) {
        if (priceBlock.basis === ServicePriceUnit.HECTARE) {
            return ServicePriceUnit.HECTARE;
        } else if (priceBlock.basis === ServicePriceUnit.SQUAREMETER) {
            value = ServicePriceUnit.SQUAREMETER;
        }
    }
    return value;
}

export function getTracksUnit(service: Service) {
    for (const priceBlock of service.calculatedServicePriceBlocks) {
        if (priceBlock.basis === ServicePriceUnit.KILOMETER) {
            return ServicePriceUnit.KILOMETER;
        } else if (priceBlock.basis === ServicePriceUnit.METER) {
            return ServicePriceUnit.METER;
        }
    }
    for (const priceBlock of service.calculatedResourcePriceBlocks) {
        if (priceBlock.basis === ServicePriceUnit.KILOMETER) {
            return ServicePriceUnit.KILOMETER;
        } else if (priceBlock.basis === ServicePriceUnit.METER) {
            return ServicePriceUnit.METER;
        }
    }
}
