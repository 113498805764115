import { User } from "firebase/auth";
import { AppCompanyContextType } from "@/components/authentication/AppCompanyContext";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { useFetchAppCompanyContextData } from "./useFetchAppCompanyContextData";
import { useFetchOrganizationContextData } from "./useFetchOrganizationContextData";

type UseLoadOrganizationDataReturn = {
    appCompanyContext: AppCompanyContextType;
    loading: boolean;
    error: boolean;
};

export function useBaseData(authUser: User | undefined): UseLoadOrganizationDataReturn {
    const gasStationsLoading = useOrganizationStore(s => s.gasStationsLoading);
    const maschinenringsLoading = useOrganizationStore(s => s.maschinenringsLoading);
    const loadingOrUnloadingPointsLoading = useOrganizationStore(s => s.loadingOrUnloadingPointsLoading);
    const machinesLoading = useOrganizationStore(s => s.machinesLoading);
    const employeesLoading = useOrganizationStore(s => s.employeesLoading);
    const servicesLoading = useOrganizationStore(s => s.servicesLoading);
    const resourcesLoading = useOrganizationStore(s => s.resourcesLoading);

    const {
        appCompanyContext,
        loading: appCompanyContextLoading,
        error: appCompanyContextError,
    } = useFetchAppCompanyContextData();

    useFetchOrganizationContextData({
        authUser,
        companyMeta: appCompanyContext.companyMeta,
    });

    const loading =
        machinesLoading ||
        servicesLoading ||
        employeesLoading ||
        resourcesLoading ||
        loadingOrUnloadingPointsLoading ||
        gasStationsLoading ||
        maschinenringsLoading ||
        appCompanyContextLoading;

    return {
        appCompanyContext: {
            ...appCompanyContext,
        },
        loading,
        error: appCompanyContextError,
    };
}
