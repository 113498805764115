import { AppUser } from "@farmact/model/src/model/AppUser";
import { ActivityType } from "@farmact/model/src/model/Employee";
import { MantineProvider, createTheme as createMantineTheme } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { ThemeProvider, createTheme } from "@mui/material";
import * as Portal from "@radix-ui/react-portal";
import * as Tooltip from "@radix-ui/react-tooltip";
import { User } from "firebase/auth";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useOrderRunDataForCache } from "@/util/customHooks/customers/useOrderRunDataForCache";
import { useGeolocation } from "@/util/customHooks/geolocationHooks/useGeolocation";
import { usePageIsVisible } from "@/util/customHooks/usePageIsVisible";
import { DeviceSettings, DeviceSettingsContext } from "@/util/deviceSettings/DeviceSettingsContext";
import { getDeviceSettings } from "@/util/deviceSettings/getDeviceSettings";
import { GeolocationContext } from "@/util/geolocation/GeolocationContext";
import AppCompanyContext, { AppCompanyContextType } from "./authentication/AppCompanyContext";
import { UserContext } from "./authentication/Session/UserContext";
import { useCustomClaims } from "./authentication/useCustomClaims";
import ContractorContext from "./Contractors/ContractorsContext";
import { useContractorContextData } from "./Contractors/useContractorContextData";
import { FaAlertProvider } from "./FaAlert/FaAlertProvider";
import * as Toast from "./FaToast";
import { ToastMessage } from "./FaToast/FaToastRoot/FaToastRoot";
import { LocationTracking } from "./LocationTracking";
import { NewVersionToast } from "./NewVersionToast";
import { useOrganizationStore } from "./organization/stores/organizationStore";
import { ToastColor, ToastContext } from "./ToastContext";
import { DatevConnectionCheck } from "./utils/DatevConnectionCheck/DatevConnectionCheck";

type ContextWrapperProps = {
    authUser: User | null | undefined;
    appUser: AppUser | undefined;
    appCompanyContext: AppCompanyContextType;
    children: ReactNode;
};

export function ContextWrapper(props: ContextWrapperProps) {
    const [message, setMessage] = useState<ToastMessage>();
    const [deviceSettings, setDeviceSettings] = useState<DeviceSettings>({});

    const [customClaims] = useCustomClaims(props.authUser);

    const { contractors, loading } = useContractorContextData(props.authUser);

    const pageIsVisible = usePageIsVisible();
    useEffect(() => {
        if (!pageIsVisible) {
            return;
        }
        getDeviceSettings().then(setDeviceSettings);
    }, [pageIsVisible]);

    const allEmployees = useOrganizationStore(s => s.employees);
    const loggedInEmployee = allEmployees.find(employee => {
        return employee.appUserId === props.authUser?.uid;
    });

    const onMessageCallback = useCallback((message: string, color: ToastColor) => {
        setMessage({ id: v4(), message, color });
    }, []);

    const { watchGeolocation, openDeviceGeolocationSettings } = useGeolocation(
        !!deviceSettings.trackBackgroundGeolocation
    );

    const ionicFontFamily = getComputedStyle(document.body).getPropertyValue("--ion-font-family");
    const mantineTheme = createMantineTheme({
        fontFamily: ionicFontFamily,
        fontSizes: { sm: "1rem" },
    });

    const { orderId, orderRunId } =
        loggedInEmployee?.currentActivity?.type === ActivityType.ORDER
            ? loggedInEmployee.currentActivity
            : {
                  orderId: undefined,
                  orderRunId: undefined,
              };

    useOrderRunDataForCache(orderId, orderRunId ?? undefined);

    return (
        <MantineProvider theme={mantineTheme}>
            <ThemeProvider
                theme={createTheme({
                    typography: {
                        fontFamily: ionicFontFamily,
                    },
                })}>
                <Tooltip.Provider>
                    <FaAlertProvider>
                        <DatesProvider settings={{ locale: "de", firstDayOfWeek: 1 }}>
                            <DeviceSettingsContext.Provider value={{ deviceSettings, setDeviceSettings }}>
                                <UserContext.Provider
                                    value={{
                                        authUser: props.authUser,
                                        appUser: props.appUser,
                                        employee: loggedInEmployee,
                                        customClaims,
                                        isLoading: loggedInEmployee === undefined,
                                    }}>
                                    <AppCompanyContext.Provider value={props.appCompanyContext}>
                                        <ContractorContext.Provider
                                            value={{
                                                contractors,
                                                loading,
                                            }}>
                                            <ToastContext.Provider
                                                value={{
                                                    onMessage: onMessageCallback,
                                                    dismissMessage: () => setMessage(undefined),
                                                }}>
                                                <GeolocationContext.Provider
                                                    value={{ watchGeolocation, openDeviceGeolocationSettings }}>
                                                    {props.children}
                                                    <LocationTracking />
                                                </GeolocationContext.Provider>
                                            </ToastContext.Provider>

                                            <Toast.Provider swipeDirection="down">
                                                <Toast.Root message={message} onDismiss={() => setMessage(undefined)} />
                                                <NewVersionToast />

                                                <Portal.Root>
                                                    <Toast.Viewport />
                                                </Portal.Root>
                                            </Toast.Provider>
                                        </ContractorContext.Provider>
                                        <DatevConnectionCheck />
                                    </AppCompanyContext.Provider>
                                </UserContext.Provider>
                            </DeviceSettingsContext.Provider>
                        </DatesProvider>
                    </FaAlertProvider>
                </Tooltip.Provider>
            </ThemeProvider>
        </MantineProvider>
    );
}
