import { AppMetaFeatureFlags } from "@farmact/model/src/model/AppMeta";
import { environment } from "@/config/config";
import { useAppMeta } from "./useAppMeta";

export function useHasFeatureFlag(featureFlag: keyof AppMetaFeatureFlags): boolean {
    const [appMeta] = useAppMeta();

    if (environment === "local") {
        return true;
    }

    if (!appMeta) {
        return false;
    }

    return appMeta.featureFlags[featureFlag];
}
