import { ActivityType } from "@farmact/model/src/model/Employee";
import { Order, TaskRecord } from "@farmact/model/src/model/Order";
import { TaskRecordType } from "@farmact/model/src/model/services/TaskRecords";
import { useEffect, useState } from "react";
import {
    createAreaTaskRecord,
    createCubicMetersTaskRecord,
    createLiterTaskRecord,
    createLoadsTaskRecord,
    createSolidCubicMetersTaskRecord,
    createTonsTaskRecord,
    createTracksTaskRecord,
} from "@/components/orders/utils/taskRecordUtils";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import { getServiceTaskRecordTypes } from "@/util/orderUtils";
import { recordError } from "@/util/recordError";

type UseInitializeStopOrderContextTaskRecordsParams = {
    enabled: boolean;
    setTaskRecords: (taskRecords: TaskRecord[]) => void;
};

type UseInitializeStopOrderContextTaskRecordsReturn = {
    loading: boolean;
    error: InitializeStopOrderContextTaskRecordsError | null;
};

export function useInitializeStopOrderContextTaskRecords(
    order: Order | null,
    params: UseInitializeStopOrderContextTaskRecordsParams
): UseInitializeStopOrderContextTaskRecordsReturn {
    const employees = useOrganizationStore(s => s.employees);
    const orderEmployee = employees.find(employee => employee.id === order?.employeeId);
    const orderEmployeeCurrentActivity = orderEmployee?.currentActivity ?? null;
    const currentlyActiveMapStructure =
        orderEmployeeCurrentActivity?.type === ActivityType.ORDER ? orderEmployeeCurrentActivity.mapStructure : null;

    const { enabled, setTaskRecords } = params;

    const [previousEnabled, setPreviousEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<InitializeStopOrderContextTaskRecordsError | null>(null);

    if (previousEnabled && !params.enabled) {
        setLoading(false);
        setError(null);
        setPreviousEnabled(false);
    }
    if (!previousEnabled && params.enabled) {
        setLoading(true);
        setError(null);
        setPreviousEnabled(true);
    }

    useEffect(() => {
        if (!enabled || !order) {
            return;
        }

        if (!order.serviceSnapshot) {
            setLoading(false);
            setError(InitializeStopOrderContextTaskRecordsError.NO_SERVICE_SNAPSHOT);
            return;
        }

        const createTaskRecords = async (): Promise<TaskRecord[]> => {
            const orderTaskRecordTypes = getServiceTaskRecordTypes(order.serviceSnapshot);
            const taskRecords: TaskRecord[] = [];
            if (orderTaskRecordTypes.includes(TaskRecordType.AREA)) {
                taskRecords.push(
                    await createAreaTaskRecord(order, order.machineSnapshots, currentlyActiveMapStructure)
                );
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.TRACKS)) {
                taskRecords.push(
                    await createTracksTaskRecord(order, order.machineSnapshots, currentlyActiveMapStructure)
                );
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.TONS)) {
                taskRecords.push(createTonsTaskRecord(order, order.machineSnapshots));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.CUBIC_METERS)) {
                taskRecords.push(createCubicMetersTaskRecord(order, order.machineSnapshots));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.SOLID_CUBIC_METERS)) {
                taskRecords.push(createSolidCubicMetersTaskRecord(order, order.machineSnapshots));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.LITER)) {
                taskRecords.push(createLiterTaskRecord(order, order.machineSnapshots));
            }
            if (orderTaskRecordTypes.includes(TaskRecordType.LOADS)) {
                taskRecords.push(createLoadsTaskRecord(order, order.machineSnapshots));
            }

            return taskRecords;
        };

        createTaskRecords()
            .then(taskRecords => {
                setTaskRecords(taskRecords);
            })
            .catch(error => {
                recordError("Failed to create task records to stop order", {
                    order,
                    error,
                });
                setError(InitializeStopOrderContextTaskRecordsError.UNKNOWN);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [enabled, setTaskRecords, order, currentlyActiveMapStructure]);

    return {
        loading,
        error,
    };
}

export enum InitializeStopOrderContextTaskRecordsError {
    NO_SERVICE_SNAPSHOT,
    UNKNOWN,
}
